import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpParamsBuilder } from '@fitech-workspace/core-lib';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CopyDeviceCmd } from '../../management/models/copyDeviceCmd';
import { DesignatorDto } from '../models/designator.model';
import { SensorThinDto } from '../models/sensors/sensor-thin-dto.model';

export const CM_BACKEND_API_URL = new InjectionToken<string>('CM_BACKEND_API_URL');

@Injectable({
	providedIn: 'root',
})
export class MasterDataApiService {
	private get _apiUrl(): string {
		return `${this.apiUrl}master-data`;
	}

	constructor(
		protected httpClient: HttpClient,
		@Optional()
		@Inject(CM_BACKEND_API_URL)
		protected apiUrl?: string
	) {}

	copyDevice(copyDeviceCmd: CopyDeviceCmd): Observable<any> {
		return this.httpClient.post<any>(`${this._apiUrl}/devices/copy`, copyDeviceCmd);
	}

	getItemsAsync(from?: Date, to?: Date, deviceImeis?: string[], lineId?: number): Observable<string[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.appendArray('deviceImeis', deviceImeis)
			.append('lineId', lineId);

		return this.httpClient.get<string[]>(`${this._apiUrl}/items`, { params: httpParamsBuilder.build() });
	}

	getComponentNamesAsync(from?: Date, to?: Date, deviceImeis?: string[], lineId?: number): Observable<string[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.appendArray('deviceImeis', deviceImeis)
			.append('lineId', lineId);

		return this.httpClient.get<string[]>(`${this._apiUrl}/components`, { params: httpParamsBuilder.build() });
	}

	getDesignatorsAsync(from?: Date, to?: Date, deviceImeis?: string[], items?: string[]): Observable<DesignatorDto[]> {
		const httpParamsBuilder: HttpParamsBuilder = HttpParamsBuilder.create()
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString())
			.appendArray('deviceImeis', deviceImeis)
			.appendArray('items', items);

		return this.httpClient.get<DesignatorDto[]>(`${this._apiUrl}/designators`, { params: httpParamsBuilder.build() });
	}

	getPointStatusesAsync(onlyPassAndProcessErrors: boolean, deviceImeis: string[], from: Date, to: Date): Observable<string[]> {
		const httpParamsBuilder = HttpParamsBuilder.create()
			.append('onlyPassAndProcessErrors', onlyPassAndProcessErrors)
			.appendArray('deviceImeis', deviceImeis)
			.append('from', moment(from).toISOString())
			.append('to', moment(to).toISOString());

		return this.httpClient.get<string[]>(`${this._apiUrl}/point-statuses`, { params: httpParamsBuilder.build() });
	}

	getFloorPlanSasUri(locationId: number, floorPlanUrl: string): Observable<string> {
		const httpParamsBuilder = HttpParamsBuilder.create().append('locationId', locationId).append('floorPlanUrl', floorPlanUrl);

		return this.httpClient.get<string>(`${this._apiUrl}/floor-plan`, { params: httpParamsBuilder.build() });
	}

	getSensorIds(sensorSourceProperties: string[], sensorsGroupIds?: number[]): Observable<SensorThinDto[]> {
		const httpParamsBuilder = HttpParamsBuilder.create()
			.appendArray('sensorSourceProperties', sensorSourceProperties)
			.appendArray('sensorsGroupsIds', sensorsGroupIds);

		return this.httpClient.get<SensorThinDto[]>(`${this._apiUrl}/sensors`, { params: httpParamsBuilder.build() });
	}

	getComponentImgSasUri(componentId: number, imgUrl: string): Observable<string> {
		const httpParamsBuilder = HttpParamsBuilder.create().append('componentId', componentId).append('imgUrl', imgUrl);

		return this.httpClient.get<string>(`${this._apiUrl}/component-img`, { params: httpParamsBuilder.build() });
	}

	uploadFloorPlan(formData: FormData): Observable<string> {
		return this.httpClient.post<string>(`${this._apiUrl}/floor-plan`, formData);
	}

	uploadComponentImg(file: File): Observable<string> {
		const formData: FormData = new FormData();
		formData.append('image', file);

		return this.httpClient.post<string>(`${this._apiUrl}/component-img`, formData);
	}
}
